<template>
  <div>
    <v-dialog v-model="imgdialog" max-width="450px">
      <v-card tile>
        <v-card-text>
          <v-container>
            <v-img contain max-height="150" :src="selectedSrc"></v-img>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="() => {
            imgdialog = false;
          }
            ">
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create Image Dialog -->

    <v-dialog v-model="imageDialog" max-width="450px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
          Create Image
        </v-btn>
      </template>

      <v-col class="flex flex-end">
        <v-icon dark medium class="mr-1" @click="dialog = false">
          mdi-close
        </v-icon>
      </v-col>

      <v-card tile>
        <v-card-title class="primary white--text">
          <span class="text-h5"><v-icon large color="white" class="mr-2">{{ imageFormIcon }}</v-icon>{{ imageFormTitle
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                <v-img contain max-height="150" :src="selectedImage"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Daily Image" outlined dense @change="getImage('daily_image')"
                  v-model="editedImageItem.image_url" prepend-icon="" append-icon="mdi-image"></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedImageItem.name" :error-messages="nameErrors" required @input="
                  editedImageIndex == -1 ? $v.editedImageItem.name.$touch() : null
                  " @blur="editedImageIndex == -1 ? $v.editedImageItem.name.$touch() : null" label="Name"
                  append-icon="mdi-rename-box"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select dense outlined v-model="editedImageItem.logo_position" :items="[
                  'Invisible',
                  'Bottom-Left',
                  'Bottom-Right',
                ]
                  " label="Logo position" :error-messages="logo_positionErrors" required @input="
    editedImageIndex == -1
      ? $v.editedImageItem.logo_position.$touch()
      : null
    " @blur="
    editedImageIndex == -1
      ? $v.editedImageItem.logo_position.$touch()
      : null
    " append-icon="mdi-arrow-decision"></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field outlined dense type="datetime-local" v-model="editedImageItem.date" required
                  label="Date"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select dense outlined v-model="editedImageItem.profile_visible" :items="['Yes', 'No']"
                  label="Profile Visibility" :error-messages="dp_visibilityErrors" required @input="
                    editedImageIndex == -1
                      ? $v.editedImageItem.profile_visible_location.$touch()
                      : null
                    " @blur="
    editedImageIndex == -1
      ? $v.editedImageItem.profile_visible_location.$touch()
      : null
    " append-icon="mdi-eye"></v-select>
              </v-col>
              <v-col cols="12">
                <v-select dense outlined v-show="editedImageItem.profile_visible == 'Yes'"
                  v-model="editedImageItem.profile_visible_location" :items="['Bottom-Left', 'Bottom-Right']"
                  label="Profile position" :error-messages="dp_positionErrors" required @input="
                    editedImageIndex == -1
                      ? $v.editedImageItem.profile_visible_location.$touch()
                      : null
                    " @blur="
    editedImageIndex == -1
      ? $v.editedImageItem.profile_visible_location.$touch()
      : null
    " append-icon="mdi-arrow-decision"></v-select>
              </v-col>
              <v-col cols="12">
                <v-select dense outlined v-model="editedImageItem.status" :items="['Active', 'Inactive']" label="Status"
                  :error-messages="statusErrors" required @input="
                    editedImageIndex == -1 ? $v.editedImageItem.status.$touch() : null
                    " @blur="
    editedImageIndex == -1 ? $v.editedImageItem.status.$touch() : null
    " append-icon="mdi-list-status"></v-select>
              </v-col>
              <v-col cols="12">
                <!-- make first letter upper case -->
                <v-select dense outlined v-model="editedImageItem.image_type"
                  :items="['All', 'Individual', 'Business', 'Political', 'NGO']" label="Type" :error-messages="typeErrors"
                  required @input="
                    editedImageIndex == -1 ? $v.editedImageItem.image_type.$touch() : null
                    " @blur="editedImageIndex == -1 ? $v.editedImageItem.image_type.$touch() : null"
                  append-icon="mdi-briefcase"></v-select>
              </v-col>
              <v-col cols="12">
                <v-select v-show="editedImageItem.image_type == 'Political'" outlined :multiple="true" dense
                  :items="politicals" v-model="editedImageItem.political_party" :error-messages="political_partyErrors"
                  required @input="editedIndex == -1 ? $v.editedImageItem.political_party.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedImageItem.political_party.$touch() : null" label="Political party"
                  append-icon="mdi-gamepad-circle"></v-select>
              </v-col>
              <v-col cols="12">
                <v-select :items="[
                  'Hindi',
                  'Marathi',
                  'Gujarati',
                  'English',
                  'Punjabi',
                  'Bengali',
                  'Telugu',
                  'Tamil',
                  'Malayalam',
                  'Manipuri'
                ]" label="Language" outlined dense required @input="$v.editedImageItem.language.$touch()"
                  @blur="$v.editedImageItem.language.$touch()" v-model="editedImageItem.language" prepend-icon=""
                  append-icon="mdi-access-point"></v-select>
              </v-col>
              <v-col cols="12">
                <v-select outlined :multiple="true" dense :items="states" v-model="editedImageItem.state" required
                  @input="editedIndex == -1 ? $v.editedImageItem.state.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedImageItem.state.$touch() : null" label="State"
                  append-icon="mdi-gamepad-circle"></v-select>
              </v-col>
              <v-col cols="12">
                <label>Show Protocol</label>
                <v-radio-group required v-model="editedImageItem.show_protocol" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="error" dense type="error">
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="closeImage"> Cancel </v-btn>
          <v-btn color="success" @click="saveImage"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Template By Date-->
    <!-- add padding -->
    <v-dialog v-model="dateDeleteDialog" max-width="450px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2 ml-3" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
          Delete Template By Date
        </v-btn>
      </template>
      <v-card>
        <v-col class="flex flex-end">
          <v-icon black medium class="mr-1" @click="dateDeleteDialog = false">
            mdi-close
          </v-icon>
        </v-col>

        <v-card-title class="error white--text">
          <v-icon color="white" class="mr-2 ">mdi-delete</v-icon>
          You sure you want to delete this item?
        </v-card-title>


        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field outlined dense type="date" class="mt-4" v-model="startDate" required
                  label="Start Date"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field outlined dense type="date" v-model="endDate" required label="End Date"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dateDeleteDialog = false">Cancel </v-btn>
          <v-btn color="success" @click="dateDeleteItems">OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>


    </v-dialog>

    <!-- Create Sub Folder Dialog -->

    <v-dialog v-model="folderDialog" max-width="450px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2 ml-3" v-bind="attrs" v-on="on">
          <v-icon dark> mdi-plus </v-icon>
          Create Sub Folder
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title class="primary white--text">
          <span class="text-h5"><v-icon large color="white" class="mr-2">{{ folderFormIcon }}</v-icon>{{ folderFormTitle
          }}sss</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedFolderImage">
                <v-img contain max-height="150" :src="selectedFolderImage"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Thumbnail Image" outlined dense @change="getImage('folder')"
                  v-model="editedFolderItem.image_url" prepend-icon="" append-icon="mdi-image"></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedFolderItem.name" required @input="
                  editedFolderIndex == -1
                    ? $v.editedFolderItem.name.$touch()
                    : null
                  " @blur="
    editedFolderIndex == -1
      ? $v.editedFolderItem.name.$touch()
      : null
    " label="Name" append-icon="mdi-rename-box"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="error" dense type="error">
                  {{ folderError }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="closeFolder"> Cancel </v-btn>
          <v-btn color="success" @click="saveFolder"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Sub Folder -->

    <v-dialog v-model="folderDialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="error white--text">
          <v-icon color="white" class="mr-2">mdi-delete</v-icon>
          You sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeFolderDelete">Cancel </v-btn>
          <v-btn color="success" @click="deleteFolderItemConfirm">OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Image -->

    <v-dialog v-model="imageDialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="error white--text">
          <v-icon color="white" class="mr-2">mdi-delete</v-icon>
          You sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeImageDelete">Cancel </v-btn>
          <v-btn color="success" @click="deleteImageItemConfirm">OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- folders displayed -->
    <v-card style="margin-top: 16px">
      <v-card-text>
        <v-container style="max-width: 72vw">
          <draggable v-model="sub_folders" draggable=".folder_items" @start="drag = true" @change="onChange" class="row">
            <v-col cols="sm-6 col-md-4 col-lg-3" :key="fol.id" v-for="fol in sub_folders" class="folder_items">
              <v-container style="position: relative">
                <button class="edit_folder" @click="editFolderItem(fol)">
                  <i aria-hidden="true" class="v-icon notranslate mdi mdi-pencil-outline theme--light"
                    style="color: blue; font-size: 20px"></i>
                </button>
                <button class="delete_folder" @click="deleteFolderItem(fol)">
                  <i aria-hidden="true" class="v-icon notranslate mdi mdi-delete-outline theme--light"
                    style="color: red; font-size: 20px"></i>
                </button>
                <v-container @click="redirectToFolderDetailsView(fol.id)" style="
                    border: 1px solid #c4c4c4;
                    border-radius: 4px;
                    margin: 10px;
                    padding: 20px 2vw 5px 2vw;
                    text-align: center;
                  ">
                  <v-img :src="fol.image_url" height="80px" width="100%" style="object-fit: cover; border-radius: 4px" />
                  <p style="
                      margin-top: 20px;
                      font-size: 18px;
                      color: black;
                      font-weight: 400;
                    ">
                    {{ fol.name }}
                  </p>
                </v-container>
              </v-container>
            </v-col>
          </draggable>
        </v-container>
      </v-card-text>
    </v-card>



    <v-data-table :headers="headers" :items="images" item-key="name" class="elevation-3">
      <template #body="props">
        <draggable :list="props.items" tag="tbody" @start="drag = true">
          <tr v-for="(   item, itemIndex   ) in    props.items   " :key="itemIndex">
            <td v-for="(   header, index   ) in    headers   " :key="index">
              <v-avatar v-if="header.value == 'image_url'" class="profile my-2" color="grey" size="80" tile
                @click="openImage(item.image_url)">
                <v-img contain v-if="item.image_url" :src="item.image_url"></v-img>
              </v-avatar>
              <p v-if="header.value == 'status'">
                {{ item.status == true ? "Active" : "Inactive" }}
              </p>
              <div v-if="header.value == 'actions'">
                <v-icon small class="mr-2 warning--text" @click="editImageItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteImageItem(item)" class="error--text">
                  mdi-delete
                </v-icon>
              </div>
              <p v-if="!['img_url', 'status', 'actions'].includes(
                header.value
              )
                ">
                {{ item[header.value] }}
              </p>
            </td>
          </tr>
        </draggable>
      </template>
      <template v-slot:no-data>
        <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
        <v-icon v-else x-large color="grey lighten-1"> mdi-tray-remove </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
//import ReFileInputCard from "../components/form/ReFileInputCard";
import draggable from "vuedraggable";


export default {
  name: "DailyImageFolderImage",
  mixins: [validationMixin],
  data: () => ({
    re: 0,
    imgdialog: false,
    selectedSrc: null,
    headers: [
      {
        text: "Daily Image",
        value: "image_url",
        sortable: false,
        align: "start",
      },
      { text: "Download Count", value: "download_count", sortable: true },
      { text: "Name", value: "name", sortable: false },
      { text: "Date", value: "date", sortable: true },
      //   { text: "SQ", value: "order_sequence", sortable: false },
      //   { text: "ID", value: "id", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    images: [],
    sub_folders: [],
    apiUrl: process.env.VUE_APP_API_URL,
    loading: true,
    imageDialog: false,
    folderDialog: false,
    imageDialogDelete: false,
    folderDialogDelete: false,
    error: "",
    editedImageIndex: -1,
    selectedImageUrl: null,
    politicals: [],
    dateDeleteDialog: false,
    startDate: null,
    endDate: null,
    states: [],
    editedImageItem: {
      folder: "",
      img: null,
      name: "",
      logo_position: "",
      profile_visible: "",
      profile_visible_location: "",
      status: "",
      language: "",
      image_type: "",
      state: [],
      political_party: '',
      show_protocol: true,
    },
    defaultImageItem: {
      folder: "",
      img: null,
      name: "",
      logo_position: "",
      profile_visible: "",
      language: "",
      profile_visible_location: "",
      status: "",
      image_type: "",
      state: [],
      political_party: '',
      show_protocol: true,
    },
    selectedImage: null,
    // folder stuff
    folderError: "",
    editedFolderIndex: -1,
    editedFolderItem: {
      name: "",
      image_url: null,
    },
    defaultFolderItem: {
      name: "",
      image_url: null,
    },
    selectedFolderImage: null,
  }),
  created() {
    this.getImages();
    this.getFolders();
    this.getAllPoliticals();
    this.getAllStates();
  },
  methods: {
    dateDeleteItems() {

      // if start or end date is empty
      if (this.startDate == null || this.endDate == null) {
        alert("Please enter a start and end date");
        return;
      }

      this.$Axios
        .post('/api/v1/image/dateDelete', {
          start_date: this.startDate,
          end_date: this.endDate,
          folder: this.folderId,
          image_type: "daily"
        })
        .then(() => {
          this.getImages();
        })
        .catch((e) => {
          console.log(e);
        })
      this.startDate = null
      this.endDate = null
      this.dateDeleteDialog = false;
    },
    onChange() {
      this.reorder()
    },
    reorder() {
      this.sub_folders.forEach((item, index) => (item.order = index))
      // put each folder to save order
      for (let i = 0; i < this.sub_folders.length; i++) {
        let updateData = {
          order: this.sub_folders[i].order,
        };
        // assume the other data is not changed
        updateData.name = this.sub_folders[i].name;
        updateData.image_url = this.sub_folders[i].image_url;
        this.$Axios({
          method: "put",
          url: "/api/v1/daily/" + this.sub_folders[i].id,
          data: updateData,
        })
          .then(() => {
            this.close();
          })
          .catch((e) => {
            console.log(e);
          });
        updateData = {}
      }
      this.getItems();
    },
    redirectToFolderDetailsView(id) {
      this.$router.push("/netaji-daily/folder-images/" + id);
      this.getFolders();
      this.getImages();
    },

    getFolders() {
      this.selectedImage = null;
      this.loading = true;
      this.$Axios
        .get(`/api/v1/daily`, {
          params: {
            parent_folder: this.folderId,
            admin: "true",
          },
        })
        // set folder=0 to get all folders
        .then((res) => {
          if (res.status === 200) {
            this.sub_folders = res.data;
            // sort by order
            this.sub_folders.sort((a, b) => {
              return a.order - b.order;
            });

          } else {
            alert(res.data["msg"]);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addSubFolder() {
      alert("Sub folder add")
      this.folderError = "";
      if (this.$v.$error) {
        return;
      }
      let data = {};
      data.name = this.editedFolderItem.name;
      data.image_url = this.selectedFolderImage;
      data.parent_folder = this.folderId;

      this.$Axios
        .post("/api/v1/daily/", data)
        .then(() => {
          this.getFolders();
          this.getImages();
          this.closeFolder();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateSubFolder() {
      alert("Sub folder update")

      // check if the name is empty
      if (this.editedFolderItem.name == "") {
        alert("Please enter a name");
        return;
      }

      let data = {};
      data.name = this.editedFolderItem.name;
      data.image_url = this.selectedFolderImage;
      data.parent_folder = this.folderId;

      console.log("Updating folder")
      this.$Axios
        .put("/api/v1/daily/" + this.editedFolderItem.id, data)
        .then(() => {
          this.getFolders();
          this.getImages();
          this.closeFolder();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openImage(src) {
      this.selectedSrc = src;
      this.imgdialog = true;
    },
    editImageItem(item) {
      this.selectedImage = item.image_url;
      this.editedImageIndex = this.images.indexOf(item);
      this.editedImageItem = Object.assign({}, item);
      this.editedImageItem.status = item.status == true ? "Active" : "Inactive";
      this.editedImageItem.image_url = null;
      this.editedImageItem.profile_visible = item.profile_visible == true ? "Yes" : "No";
      this.imageDialog = true;
    },
    deleteImageItem(item) {
      this.editedImageIndex = this.images.indexOf(item);
      this.editedImageItem = Object.assign({}, item);
      this.imageDialogDelete = true;
    },
    deleteImageItemConfirm() {
      this.deleteImage(this.editedImageItem.id);
      this.closeImageDelete();
    },

    // folder stuff
    editFolderItem(item) {
      this.selectedFolderImage = item.image_url;
      this.editedFolderIndex = this.sub_folders.indexOf(item);
      this.editedFolderItem = Object.assign({}, item);
      this.editedFolderItem.status = item.status == 1 ? "Active" : "Inactive";
      this.editedFolderItem.image_url = null;
      this.folderDialog = true;
    },
    deleteFolderItem(item) {
      this.editedFolderIndex = this.images.indexOf(item);
      this.editedFolderItem = Object.assign({}, item);
      this.folderDialogDelete = true;
    },
    deleteFolderItemConfirm() {
      this.deleteFolder(this.editedFolderItem.id);
      this.closeFolderDelete();
    },
    deleteFolder(id) {
      this.$Axios
        .delete("/api/v1/daily/" + id, {
        })
        .then(() => {
          this.getFolders();
          this.closeFolderDelete();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    closeImage() {
      this.imageDialog = false;
      this.editedImageItem = Object.assign({}, this.defaultImageItem);
      this.selectedImage = null;
      this.$v.$reset();
      this.error = "";
    },
    closeFolder() {
      this.folderDialog = false;
      this.$v.$reset();
      this.error = "";
    },
    closeImageDelete() {
      this.imageDialogDelete = false;
    },
    closeFolderDelete() {
      this.folderDialogDelete = false;
    },
    saveImage() {
      this.editedImageIndex == -1 ? this.addImage() : this.updateImage();
    },
    saveFolder() {
      this.editedFolderIndex == -1 ? this.addSubFolder() : this.updateSubFolder();
    },
    getImage(src) {
      let formData = new FormData();
      if (src === "folder") {
        formData.append("img", this.editedFolderItem.image_url);
      } else {
        formData.append("img", this.editedImageItem.image_url);
      }

      formData.append('source', src);

      this.$Axios({
        method: 'post',
        url: '/api/v1/util/upload-image',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(({ data }) => {
          if (src === "folder") {
            this.selectedFolderImage = data.img;
          } else {
            this.selectedImage = data.img;
          }
        })
        .catch((e) => {
          console.log(e)
        });
    },
    getImages() {
      this.loading = true;
      this.$Axios
        .get("/api/v1/image/daily", {
          params: {
            folder: this.folderId,
            image_type: "daily"
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.images = res.data;
            // add seconds to the date
            this.images.forEach((el) => {
              el.date = el.date.slice(0, 19);
            });
            // make the first letter of the image type upper case
            this.images.forEach((el) => {
              el.image_type = el.image_type.charAt(0).toUpperCase() + el.image_type.slice(1);
            });
            console.log(res.data);
          } else {
            alert(res.data["msg"]);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addImage() {
      console.log(this.editedImageItem);
      this.error = "";
      this.$v.$touch();
      console.log(this.$v);
      if (this.$v.$error) {
        alert("Please fill all the required fields: " + this.$v.$error);
        return;
      }
      let data = {};
      data.image_url = this.selectedImage;
      data.feature_type = "daily";
      data.folder = this.folderId;
      data.name = this.editedImageItem.name;
      data.logo_position = this.editedImageItem.logo_position;
      data.profile_visible = this.editedImageItem.profile_visible;
      if (this.editedImageItem.profile_visible == "Yes")
        data.profile_visible_location = this.editedImageItem.profile_visible_location;
      data.status = this.editedImageItem.status == "Active" ? "true" : "false";
      data.image_type = this.editedImageItem.image_type.toLowerCase();
      data.date = this.editedImageItem.date + ":00";
      data.language = this.editedImageItem.language;
      data.show_protocol = this.editedImageItem.show_protocol;

      if (this.editedImageItem.image_type == "Political") {
        // if the political_party option selected is "Select All" then send all the political parties
        if (this.editedImageItem.political_party.includes("Select All")) {
          data.political_party = this.politicals.map((el) => {
            return el.value;
          });
          data.political_party.shift();
        } else {
          data.political_party = this.editedImageItem.political_party;
        }
      }
      // if the state option selected is "Select All" then send all the states
      if (this.editedImageItem.state.includes("Select All")) {
        data.state = this.states.map((el) => {
          return el.value;
        });
        // remove the "Select All" option from the array
        data.state.shift();

      } else {
        data.state = this.editedImageItem.state;
      }





      this.$Axios
        .post("/api/v1/image/", data)
        .then(() => {
          // reset form
          this.editedImageItem = Object.assign({}, this.defaultImageItem);
          this.selectedImage = null;
          this.editedImageIndex = -1;
          this.getImages();
          this.closeImage();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateImage() {
      console.log(this.editedImageItem);
      this.error = "";
      this.editedImageItem.image_url = this.selectedImage;
      this.$v.$touch();

      console.log(this.$v);
      if (this.$v.$error) {
        return;
      }
      let updateData = {};
      if (this.editedImageItem.name) updateData.name = this.editedImageItem.name;
      if (this.editedImageItem.image_url)
        updateData.image_url = this.selectedImage;
      if (this.editedImageItem.logo_position)
        updateData.logo_position = this.editedImageItem.logo_position;
      if (this.editedImageItem.profile_visible_location)
        updateData.profile_visible_location = this.editedImageItem.profile_visible_location;

      if (this.editedImageItem.profile_visible == "No") {
        updateData.profile_visible_location = "";
        updateData.profile_visible = this.editedImageItem.profile_visible;
      } else {
        updateData.profile_visible_location = this.editedImageItem.profile_visible_location;
        updateData.profile_visible = this.editedImageItem.profile_visible;
      }

      if (this.editedImageItem.status)
        updateData.status = this.editedImageItem.status == "Active" ? "true" : "false";
      if (this.editedImageItem.image_type) updateData.image_type = this.editedImageItem.image_type.toLowerCase();
      updateData.date = this.editedImageItem.date + ":00";
      updateData.language = this.editedImageItem.language;
      updateData.show_protocol = this.editedImageItem.show_protocol;

      if (this.editedImageItem.image_type == "Political") {
        // if the political_party option selected is "Select All" then send all the political parties
        if (this.editedImageItem.political_party.includes("Select All")) {
          updateData.political_party = this.politicals.map((el) => {
            return el.value;
          });
          updateData.political_party.shift();
        } else {
          updateData.political_party = this.editedImageItem.political_party;
        }
      }
      if (this.editedImageItem.state.includes("Select All")) {
        updateData.state = this.states.map((el) => {
          return el.value;
        });
        // remove the "Select All" option from the array
        updateData.state.shift();
      } else {
        updateData.state = this.editedImageItem.state;
      }


      console.log(`updating item : ${this.editedImageItem.id}`, updateData);
      this.$Axios({
        method: "put",
        url: "/api/v1/image/" + this.editedImageItem.id + "/",
        data: updateData,
      })
        .then(() => {
          this.editedImageItem = Object.assign({}, this.defaultImageItem);
          this.selectedImage = null;
          this.editedImageIndex = -1;
          this.getImages();
          this.closeImage();
        })
        .catch((e) => {
          this.editedImageItem.image_url = null;
          console.log(e.response);
        });
    },
    deleteImage(id) {
      this.$Axios
        .delete("/api/v1/image/" + id)
        .then(() => {
          this.getImages();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllPoliticals() {
      this.$Axios
        .get('/api/v1/political/')
        .then((res) => {
          this.politicals = res.data.map((el) => {
            return { value: el.id, text: el.name }
          })
          // add the "Select All" option to the array
          this.politicals.unshift({ value: "Select All", text: "Select All" });
        })
        .catch((e) => {
          console.log(e);
        })
    },
    getAllStates() {
      this.$Axios
        .get('/api/v1/state/')
        .then((res) => {
          this.states = res.data.map((el) => {
            return { value: el.id, text: el.name }
          })
          // add the "Select All" option to the array
          this.states.unshift({ value: "Select All", text: "Select All" });
        })
        .catch((e) => {
          console.log(e);
        })
    }
  },
  computed: {
    folderId() {
      return this.$route.params.folderId;
    },
    imageFormTitle() {
      return this.editedImageIndex === -1 ? "New Daily Image" : "Edit Daily Image";
    },
    imageFormIcon() {
      return this.editedImageIndex === -1 ? "mdi-plus" : "mdi-edit";
    },
    folderFormTitle() {
      return this.editedFolderIndex === -1 ? "New Daily Sub Folder" : "Edit Daily Sub Folder";
    },
    folderFormIcon() {
      return this.editedFolderIndex === -1 ? "mdi-plus" : "mdi-edit";
    },
    filtredItems() {
      if (this.filter)
        return this.desserts.filter(
          (item) =>
            item.name.includes(this.filter) ||
            item.license.includes(this.filter) ||
            item.email.includes(this.filter) ||
            item.db.includes(this.filter)
        );
      else return this.desserts;
    },
    //// Vuelidate Errors
    img_urlErrors() {
      const errors = [];
      if (!this.$v.editedImageItem.image_url.$dirty) return errors;
      !this.$v.editedImageItem.image_url.required &&
        errors.push("Daily Image is required !");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.editedImageItem.name.$dirty) return errors;
      !this.$v.editedImageItem.name.required && errors.push("Name is required !");
      return errors;
    },
    logo_positionErrors() {
      const errors = [];
      if (!this.$v.editedImageItem.logo_position.$dirty) return errors;
      !this.$v.editedImageItem.logo_position.required &&
        errors.push("Logo position is required");
      return errors;
    },
    dp_visibilityErrors() {
      const errors = [];
      if (!this.$v.editedImageItem.profile_visible.$dirty) return errors;
      !this.$v.editedImageItem.profile_visible.required &&
        errors.push("Is DP Visible is required");
      return errors;
    },
    dp_positionErrors() {
      const errors = [];
      if (!this.$v.editedImageItem.profile_visible_location.$dirty) return errors;
      if (this.editedImageItem.profile_visible_location == "Bottom-Left" || this.editedImageItem.profile_visible_location == "Bottom-Right") {
        return [];
      }
      !this.$v.editedImageItem.profile_visible_location.required && errors.push("Position is required");
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.editedImageItem.status.$dirty) return errors;
      !this.$v.editedImageItem.status.required && errors.push("Status is required");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.editedImageItem.image_type.$dirty) return errors;
      !this.$v.editedImageItem.image_type.required && errors.push("Type is required");
      return errors;
    },
  },
  watch: {
    imageDialog(val) {
      val || this.close();
    },
    folderDialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  validations: {
    editedImageItem: {
      image_url: {
        required,
      },
      name: {
        required,
      },
      logo_position: {
        required,
      },
      profile_visible_location: {
        required: requiredIf(function () {
          return this.editedImageItem.profile_visible == "Yes";
        }),
      },
      profile_visible: {
        required,
      },
      status: {
        required,
      },
      image_type: {
        required,
      },
    },
  },
  components: {
    draggable,
  },
};
</script>
<style scoped>
button.delete_folder {
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  box-shadow: 0 0 8px 2px #ccc;
}

button.edit_folder {
  position: absolute;
  top: 75px;
  right: 10px;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  box-shadow: 0 0 8px 2px #ccc;
}
</style>

<style scoped>
button.delete_folder {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  box-shadow: 0 0 8px 2px #ccc;
}
</style>
